<template>
  <div>
    <a-card title="工序检测">
      <a-row :gutter="[12, 24]">
        <a-col :span="24" style="width: 240px">
          <a-input v-model="searchForm.search" placeholder="质检单号, 生产工单号" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 200px">
          <a-select
            v-model="searchForm.inspection_status"
            placeholder="检验状态"
            allowClear
            style="width: 100%"
            @change="search"
          >
            <a-select-option value="pending">待质检</a-select-option>
            <a-select-option value="pass">合格</a-select-option>
            <a-select-option value="fail">不合格</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 100px">
          <a-button type="primary" icon="search" :loading="dataLoading" @click="search" style="width: 100%">查询</a-button>
        </a-col>

        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :dataSource="dataItems"
            :loading="dataLoading"
            :pagination="pagination"
            :scroll="{ x: 1600 }"
            @change="changeTable"
          >
            <template slot="number" slot-scope="value, item">
              <a @click="jumpProcessInspectionDetailPage(item)">{{ value }}</a>
            </template>
            <template slot="production_order_number" slot-scope="value, item">
              <a @click="jumpProductionOrderDetailPage(item)">{{ value }}</a>
            </template>
            <template slot="action" slot-scope="value, item">
              <TableAction :item="item" @update="update" @destroy="destroy" @refresh="list" />
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { replaceItem } from "@/utils/functions";
import { processInspectionTaskList } from "@/api/inspection";

export default {
  components: {
    TableAction: () => import("./TableAction"),
  },
  data() {
    return {
      searchForm: { search: "", page: 1, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 10 },
      dataLoading: false,

      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          fixed: "left",
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "质检单号",
          dataIndex: "number",
          width: 160,
          fixed: "left",
          scopedSlots: { customRender: "number" },
        },
        {
          title: "生产工单号",
          dataIndex: "production_order_number",
          width: 160,
          scopedSlots: { customRender: "production_order_number" },
        },
        {
          title: "检测状态",
          dataIndex: "inspection_status_display",
        },
        {
          title: "物料编号",
          dataIndex: "goods_number",
          width: 160,
        },
        {
          title: "物料名称",
          dataIndex: "goods_name",
        },
        {
          title: "检测方式",
          dataIndex: "inspection_method_display",
        },
        {
          title: "检测工序",
          dataIndex: "process_name",
        },
        {
          title: "检验数量",
          dataIndex: "inspection_quantity",
        },
        {
          title: "创建人",
          dataIndex: "creator_name",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          width: 160,
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 120,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataItems: [],
    };
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    list() {
      this.dataLoading = true;
      processInspectionTaskList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    update(item) {
      this.dataItems = replaceItem(this.dataItems, item);
    },
    changeTable(pagination, _filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      if (sorter.field) {
        this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      }
      this.list();
    },
    jumpProcessInspectionDetailPage(item) {
      this.$router.push({ path: "/inspection/process_inspection_detail", query: { id: item.id } });
    },
    jumpProductionOrderDetailPage(item) {
      this.$router.push({ path: "/production/detial", query: { id: item.production_order } });
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
